var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          {
            staticClass: "inner-container only-heading-part header-with-banner",
          },
          [
            _c(
              "v-container",
              {
                staticClass: "v-main-content only-simple-content",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-4 rounded-lg extra-padding",
                      attrs: { elevation: "3" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "app" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "px-0 pt-0 pb-0" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "sub-heading-with-btn d-flex flex-wrap",
                                },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "black-text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("keyOpt.keyBid")) +
                                          " "
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            "max-width": "300px",
                                            color: "primarydark",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "greyxdark--text ml-1",
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "mdi-information-outline"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("keyOpt.keyToolTip")
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "v-search-with-btn w-md-100",
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          width: "200",
                                          "append-icon": "mdi-magnify",
                                          label: _vm.$t("labels.search"),
                                          "hide-details": "",
                                          disabled: _vm.disableOptimizeButton,
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function ($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search",
                                        },
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary ml-3",
                                            depressed: "",
                                            disabled: _vm.disableOptimizeButton,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.enableOptimizeAllDialog()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("keyOpt.optAll")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("v-data-table", {
                            staticClass: "elevation-0 pa-0 mt-4 table-style-3",
                            attrs: {
                              loading:
                                _vm.$apollo.queries.targKeyReportsForKeyOpt
                                  .loading,
                              "items-per-page": 5,
                              headers: _vm.keyheaders,
                              items:
                                (_vm.keywordsReport &&
                                  _vm.keywordsReport.row) ||
                                [],
                              search: _vm.search,
                              "no-data-text": _vm.$t("keyOpt.noData"),
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(
                                  Object.keys(_vm.$scopedSlots),
                                  function (slot) {
                                    return {
                                      key: slot,
                                      fn: function (scope) {
                                        return [_vm._t(slot, null, null, scope)]
                                      },
                                    }
                                  }
                                ),
                                {
                                  key: "item.Accept",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "no-m-no-p",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "primary",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.bidAmount(item)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("keyOpt.opt")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.calcEventCount",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.calcEventCount(
                                              item,
                                              "keywordAttributionData"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
              ],
              2
            ),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "600px", persistent: "" },
                model: {
                  value: _vm.bidAmountDialog,
                  callback: function ($$v) {
                    _vm.bidAmountDialog = $$v
                  },
                  expression: "bidAmountDialog",
                },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "v-modal-fixed style-2" },
                  [
                    _c(
                      "v-card-title",
                      {
                        staticClass:
                          "headline d-flex justify-space-between px-5 py-3 v-modal-header",
                      },
                      [
                        _c("h6", [_vm._v(_vm._s(_vm.$t("keyOpt.optBidAmt")))]),
                        _c(
                          "v-icon",
                          {
                            staticClass: "black--text",
                            attrs: { disabled: _vm.saving },
                            on: {
                              click: function ($event) {
                                _vm.bidAmountDialog = false
                              },
                            },
                          },
                          [_vm._v("mdi-close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      [
                        [
                          _c(
                            "v-container",
                            { staticClass: "pa-0", attrs: { height: "150" } },
                            [
                              _c("h5", [
                                _vm._v(
                                  _vm._s(_vm.$t("keyOpt.key")) +
                                    ": " +
                                    _vm._s(_vm.keywordText)
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: { row: "" },
                                      model: {
                                        value: _vm.selectionRadio,
                                        callback: function ($$v) {
                                          _vm.selectionRadio = $$v
                                        },
                                        expression: "selectionRadio",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Opt for Min Bid",
                                          value: "Min",
                                        },
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Opt for Max Bid",
                                          value: "Max",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.selectionRadio == "Min"
                                    ? _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          "prepend-inner-icon":
                                            "mdi-currency-usd",
                                        },
                                        model: {
                                          value:
                                            _vm.detailsForUpdate.minBidAmount
                                              .amount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detailsForUpdate.minBidAmount,
                                              "amount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detailsForUpdate.minBidAmount.amount",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.selectionRadio == "Max"
                                    ? _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          "prepend-inner-icon":
                                            "mdi-currency-usd",
                                        },
                                        model: {
                                          value:
                                            _vm.detailsForUpdate.maxBidAmount
                                              .amount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detailsForUpdate.maxBidAmount,
                                              "amount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detailsForUpdate.maxBidAmount.amount",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                      ],
                      2
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "justify-end mt-0 pa-5" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              width: "127",
                              color: "grey",
                              depressed: "",
                              disabled: _vm.saving,
                              outlined: "",
                            },
                            on: {
                              click: function ($event) {
                                _vm.bidAmountDialog = false
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              width: "127",
                              color: "primary",
                              depressed: "",
                              loading: _vm.saving,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeKeywordBidAmount()
                              },
                            },
                          },
                          [_vm._v("Apply")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "600px", persistent: "" },
                model: {
                  value: _vm.optimizeAllDialog,
                  callback: function ($$v) {
                    _vm.optimizeAllDialog = $$v
                  },
                  expression: "optimizeAllDialog",
                },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "v-modal-fixed style-2" },
                  [
                    _c(
                      "v-card-title",
                      {
                        staticClass:
                          "headline d-flex justify-space-between px-5 py-3 v-modal-header",
                      },
                      [
                        _c("h6", { staticClass: "text-h6 word-break" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("keyOpt.optBidForAll")) + " "
                          ),
                        ]),
                        _c(
                          "v-icon",
                          {
                            staticClass: "black--text",
                            attrs: { disabled: _vm.saving },
                            on: {
                              click: function ($event) {
                                _vm.optimizeAllDialog = false
                              },
                            },
                          },
                          [_vm._v("mdi-close")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      { staticClass: "pt-4 pb-4" },
                      [
                        [
                          _c(
                            "v-container",
                            { staticClass: "pa-0", attrs: { height: "150" } },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      attrs: { row: "", width: "116px" },
                                      model: {
                                        value: _vm.optimizeAllRadio,
                                        callback: function ($$v) {
                                          _vm.optimizeAllRadio = $$v
                                        },
                                        expression: "optimizeAllRadio",
                                      },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Opt for Min Bid",
                                          value: "Min",
                                        },
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Opt for Max Bid",
                                          value: "Max",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                      ],
                      2
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "justify-end mt-0 pa-5" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              width: "127",
                              color: "grey",
                              depressed: "",
                              disabled: _vm.saving,
                              outlined: "",
                            },
                            on: {
                              click: function ($event) {
                                _vm.optimizeAllDialog = false
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              width: "127",
                              color: "primary",
                              depressed: "",
                              loading: _vm.saving,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateAllBidAmount()
                              },
                            },
                          },
                          [_vm._v("Apply")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("PermissionReadOnlyPopup", {
        attrs: { permissionMetadata: _vm.permissionReadOnlyMetadata },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }